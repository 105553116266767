// Vendor
import axios from "axios";
import { createPinia, PiniaVuePlugin } from "pinia";
import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
import Vue, { watch } from "vue";
import VueMeta from "vue-meta";
import VueNotification from "vue-notification";
import * as Sentry from "@sentry/vue";
// Store
import { useAuthenticationStore } from "@/store/authentication";
// App
import App from "./App.vue";
// Router
import router from "./router";
// Pinia start ------------------------------------------------------------
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
// Use persisted state
if (localStorage.getItem("state")) {
    pinia.state.value = JSON.parse(localStorage.getItem("state") || "");
}
// Persist state
watch(pinia.state, (state) => {
    localStorage.setItem("state", JSON.stringify(state));
}, {
    deep: true
});
// Pinia end --------------------------------------------------------------
// Tooltip
Vue.use(Tooltip);
// Meta data with vue-meta
Vue.use(VueMeta);
// Notifications
Vue.use(VueNotification);
Vue.config.productionTip = false;
// Sentry
Sentry.init({
    Vue,
    dsn: "https://f4a7af86f47949bf98fb52cd96fe2c8b@logs.gce.p.meinauto.de:7443/49",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
});
new Vue({
    pinia,
    router,
    render: (h) => h(App)
}).$mount("#app");
// Add app-client and auth-token via interceptor to each request
axios
    .interceptors
    .request
    .use((config) => {
    var _a;
    // @ts-ignore
    config === null || config === void 0 ? void 0 : config.headers["X-APP-CLIENT"] = "MVV";
    // @ts-ignore
    (_a = config === null || config === void 0 ? void 0 : config.headers) === null || _a === void 0 ? void 0 : _a.Authorization = useAuthenticationStore().getAuthToken
        ? `bearer ${useAuthenticationStore().getAuthToken}`
        : null;
    return config;
});
// Add a response interceptor
// eslint-disable-next-line
axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do nothing with response data
    return response;
    // eslint-disable-next-line
}, (error) => {
    var _a, _b, _c;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && !/.*credentials.*/.test((_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message)) {
        // Log Error
        Sentry.withScope((scope) => {
            scope.setExtra("Status", error === null || error === void 0 ? void 0 : error.status);
            scope.setExtra("Data", JSON.stringify(error === null || error === void 0 ? void 0 : error.data));
            Sentry.captureMessage("Authorization error", "error");
        });
        router.push("/sitzung-abgelaufen");
    }
    // Continue with response
    return Promise.reject(error);
});
