export default class Configuration {
    static get CONFIG() {
        return {
            VUE_APP_BACKEND_HOST: "$VUE_APP_BACKEND_HOST",
            VUE_APP_CONTENTFUL_ACCESS_TOKEN: "$VUE_APP_CONTENTFUL_ACCESS_TOKEN",
            VUE_APP_CONTENTFUL_HOST: "$VUE_APP_CONTENTFUL_HOST",
            VUE_APP_CONTENTFUL_SPACE_ID: "$VUE_APP_CONTENTFUL_SPACE_ID",
            VUE_APP_ENVIRONMENT: "$VUE_APP_ENVIRONMENT",
            VUE_APP_SENTRY_LOGGER: "$VUE_APP_SENTRY_LOGGER"
        };
    }

    static value(name) {
        if (!(name in this.CONFIG)) {
            if (process.env.NODE_ENV !== "production") {
                // eslint-disable-next-line no-console
                console.log(`Configuration: There is no key named "${name}"`);
            }
            return;
        }

        const value = this.CONFIG[name];

        if (!value) {
            if (process.env.NODE_ENV !== "production") {
                // eslint-disable-next-line no-console
                console.log(`Configuration: Value for "${name}" is not defined`);
            }
            return;
        }

        if (value.startsWith("$VUE_APP_")) {
            // value was not replaced, it seems we are in development.
            // Remove $ and get current value from process.env
            const envName = value.substr(1);
            const envValue = process.env[envName];
            if (envValue) {
                // eslint-disable-next-line consistent-return
                return envValue;
            }
            if (process.env.NODE_ENV !== "production") {
                // eslint-disable-next-line no-console
                console.log(`Configuration: Environment variable "${envName}" is not defined`);
            }
        } else {
            // value was already replaced, it seems we are in production.
            // eslint-disable-next-line consistent-return
            return value;
        }
    }
}
